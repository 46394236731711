import { queryClient } from 'index';
import { QueryServerKeys } from 'QueryServerKeys';
import { useMutation, useQuery } from 'react-query';
import {
  getClusterConfig,
  getClusterInfo,
  saveClusterConfig,
} from 'services/cluster';
import { ClusterConfigurations } from 'types/cluster.type';
import { convertRecordToProperties } from 'utils/cluster';

export function useGetClusterInfo({ clusterName }: { clusterName: string }) {
  const queryOptions = useQuery(
    QueryServerKeys.CLUSTER.GET_CLUSTER_INFO,
    async () => {
      const res = await getClusterInfo(clusterName);
      return res?.data;
    }
  );

  return queryOptions;
}

export function useSaveClusterConfig() {
  const queryOptions = useMutation(
    async ({ config }: { config: ClusterConfigurations }) => {
      return await saveClusterConfig({ config });
    },
    {
      mutationKey: [QueryServerKeys.CLUSTER.SET_CLUSTER_CONFIG],
      onSuccess() {
        queryClient.invalidateQueries(
          QueryServerKeys.CLUSTER.GET_CLUSTER_CONFIG
        );
      },
      onError(error: any) {
        throw new Error(error);
      },
    }
  );

  return queryOptions;
}

export function useGetClusterConfig() {
  const queryOptions = useQuery(
    QueryServerKeys.CLUSTER.GET_CLUSTER_CONFIG,
    async () => {
      const res = await getClusterConfig();
      return res?.data;
    },
    {
      select(data) {
        const sparkProperties = convertRecordToProperties(
          data.sparkSafeGuardProperties || {}
        );
        return { ...data, sparkSafeGuardProperties: sparkProperties };
      },
    }
  );

  return queryOptions;
}
