import React, { useContext } from "react";
import "./StatusStatistics.scss";
import { Card, Row, Statistic } from "antd";
import { useStatusCounts } from "hooks/jobs";
import { AppContext } from "contexts/AppContext";
import { FormattedMessage } from "react-intl";
import { adminPathName } from "types/types";
import useSignedURL from "hooks/useSignedURL";

function StatusStatistics() {
  const { clusterName, pathname } = useContext(AppContext);
  const { isSignedURL } = useSignedURL();

  const isEnabled =
    (pathname.includes(adminPathName) || !!clusterName) && !isSignedURL
      ? true
      : false;

  const { data: statusUnfilteredCounts, isLoading: isStatusCountsLoading } =
    useStatusCounts({
      clusterName: clusterName as string,
      isEnabled,
    });

  if (isSignedURL) {
    return null;
  }
  return (
    <Row className="status-card-list__wrapper">
      <Card
        className="status-card__wrapper status-card__wrapper--in-queue"
        bordered={false}
      >
        <Statistic
          title={<FormattedMessage id="filters.status.inQueue" />}
          value={statusUnfilteredCounts?.data?.In_Queue}
          loading={isStatusCountsLoading}
        />
      </Card>
      <Card
        className="status-card__wrapper status-card__wrapper--running"
        bordered={false}
      >
        <Statistic
          title={<FormattedMessage id="filters.status.running" />}
          value={statusUnfilteredCounts?.data?.Running}
          loading={isStatusCountsLoading}
        />
      </Card>
      <Card
        className="status-card__wrapper status-card__wrapper--succeeded"
        bordered={false}
      >
        <Statistic
          title={<FormattedMessage id="filters.status.succeeded" />}
          value={statusUnfilteredCounts?.data?.Succeeded}
          loading={isStatusCountsLoading}
        />
      </Card>
      <Card
        className="status-card__wrapper status-card__wrapper--failed"
        bordered={false}
      >
        <Statistic
          title={<FormattedMessage id="filters.status.failed" />}
          value={statusUnfilteredCounts?.data?.Failed}
          loading={isStatusCountsLoading}
        />
      </Card>
      <Card
        className="status-card__wrapper status-card__wrapper--cancelled"
        bordered={false}
      >
        <Statistic
          title={<FormattedMessage id="filters.status.cancelled" />}
          value={statusUnfilteredCounts?.data?.Cancelled}
          loading={isStatusCountsLoading}
        />
      </Card>
      <Card
        className="status-card__wrapper status-card__wrapper--timed-out"
        bordered={false}
      >
        <Statistic
          title={<FormattedMessage id="filters.status.timed-out" />}
          value={statusUnfilteredCounts?.data?.Timed_Out}
          loading={isStatusCountsLoading}
        />
      </Card>
    </Row>
  );
}

export default StatusStatistics;
