import axios, { AxiosResponse } from 'axios';
import { ClusterConfigurations, ClusterResponse } from 'types/cluster.type';
import { API_URL } from 'utils/http';

export async function getClusterInfo(clusterName: string) {
  try {
    const res = await axios.get<ClusterResponse>(
      `${API_URL}/cluster?cluster_name=${clusterName}`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function saveClusterConfig({
  config,
}: {
  config: ClusterConfigurations;
}) {
  try {
    const res = await axios.post(
      `${API_URL}/config`,
      config
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getClusterConfig () {
  try {
    const res = await axios.get<ClusterConfigurations>(
      `${API_URL}/config`);
    return res;
  } catch (error) {
    throw error;
  }
}
